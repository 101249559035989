/* eslint-disable import/no-unresolved */
import '@styles/fonts.css';
import 'swiper/css';
import 'swiper/css/autoplay';

import theme from '@public/themes';
import GlobalStyles from '@styles/global';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider } from 'styled-components';

const CookieConsent = dynamic(() => import('@components/CookieConsent'), {
  ssr: false,
});

function App({ Component, pageProps }) {
  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ff6a00" />
        <meta name="msapplication-TileColor" content="#da532c" />
      </Head>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
        <CookieConsent />
      </ThemeProvider>
    </>
  );
}

export default appWithTranslation(App);
