const theme = {
  colors: {
    primary: {
      orange: '#FF6A00',
      orange_700: '#FF8833',
      hover_orange: '#FF8F3E',
      black: '#161616',
      red: '#EF4444',
      green: '#10B981',
      light_500: '#171717',
      light_400: '#5C5C5C',
      light_300: '#D1D1D1',
      light_200: '#A3A3A3',
      dark: '#000000',
      white: '#F7F7F7',
    },
    secondary: {
      silver_1000: '#FFFFFF',
      silver_900: '#E8E8E8',
      silver_800: '#D2D2D2',
      silver_700: '#BBBBBB',
      silver_600: '#A5A5A5',
      silver_500: '#8E8E8E',
      silver_400: '#777777',
      silver_300: '#616161',
      silver_200: '#4A4A4A',
      silver_100: '#343434',
      silver_70: '#454545',
      silver_60: '#8A8A8A',
      silver_50: '#282828',
      silver_40: '#BABABA',
      silver_30: '#737373',
      silver_20: '#222222',
    },
  },
};

export default theme;
