import theme from '@public/themes/index';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background: #161616;
    overflow-x: hidden;
    scroll-behavior: auto;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  /* slider */
  .swiper-pagination-bullet {
    width: 60px;
    height: 4px;
    background: ${theme.colors.secondary.silver_300};
  }
  .swiper-pagination-bullet:hover {
    cursor: pointer;
    background: ${theme.colors.primary.orange};
  }
  .swiper-pagination-bullet-active {
    background: ${theme.colors.primary.orange};
  }

  /* match */
  * {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: Pragmatica;
  }
`;

export default GlobalStyles;
